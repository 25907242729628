import React from "react";
import EmptySpace from "@components/ui/empty-space";
import Layout from "@components/domain/shared/layout";
import Spacing from "@components/ui/spacing";
import FluidCourseCard from "@components/ui/fluid-course-card";
import Sponsors from "@components/ui/sponsors";
import DesktopNewsletter from "@components/ui/desktop-newsletter";
import SEO from "@components/ui/seo";
import styles from "@styles/pages/candidatura.module.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import CourseSuggestionForm from "@components/ui/course-suggestion-form/index";
import BackgroundImage from "gatsby-background-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import {
  isCourseOpenForSignUp,
  getPathToCoursePage,
  getEndDateLabel,
  getImageNodeByName,
} from "@utilities/course-data-helpers";
import { useCoursesMetadata } from "@queries/use-courses-metadata";

const Applications = (props) => {
  const breakpoints = useBreakpoint();
  const {
    allCourses
  } = useCoursesMetadata();
  const coursesWithOpenApplication = allCourses.filter(
    isCourseOpenForSignUp
  );
  const imageHeigth = breakpoints.tablet ? 300 : breakpoints.mobile ? 250 : 549;

  return (
    <Layout>
      <SEO
        title="Candidata-te"
        description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática."
      />
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
      <section className={styles.description}>
        <div className={styles.textBox}>
          <div className={styles.textBoxContent}>
            <h2>Candidata-te e</h2>
            <h2>vai além da escola!</h2>
            <p>
              O After School funciona num regime de aula invertida (flipped
              classroom). Isto significa que, em casa, os alunos leem, pensam e
              assistem a vídeos com os conteúdos programáticos. Nas aulas,
              resolvem exercícios e esclarecem dúvidas com os nossos
              instrutores.
            </p>
          </div>
        </div>
        <div className={styles.largeImage}>
          <BackgroundImage
            style={{ height: imageHeigth, width: "100%" }}
            fluid={
              getImageNodeByName("girl-with-backpack", props.data.images)
                .childImageSharp.fluid
            }
          />
        </div>
      </section>

      {coursesWithOpenApplication.length > 0 && (
        <div className={styles.openCourses}>
          <h2>Cursos com inscrições abertas</h2>
          {[...coursesWithOpenApplication]
            .sort((a, b) => a.course.name.localeCompare(b.course.name))
            .map((node) => {
              const { course_iter_id, scholarity, applications_end_date, course: {name: course_name}, image} = node;

              return (
                <div className={styles.fluidCard} key={course_iter_id}>
                  <FluidCourseCard
                    courseName={course_name}
                    scholarity={scholarity}
                    endDate={getEndDateLabel(applications_end_date)}
                    path={getPathToCoursePage(node)}
                    fluidImage={
                      image !== null ? image.localFile.childImageSharp.fluid : null
                    }
                    applicationPath={`${getPathToCoursePage(node)}/candidatura`}
                  />
                </div>
              );
            })}
        </div>
      )}
      <EmptySpace desktop={{ margin: 40 }} mobile={{ margin: 20 }} />
      {/*<Spacing>
         <CourseSuggestionForm courseNames={data} /> 
        &nbsp;
      </Spacing>*/}

      <DesktopNewsletter />

      <Spacing>
        <EmptySpace desktop={{ margin: 40 }} mobile={{ isHidden: true }} />
        <EmptySpace
          desktop={{ margin: 200, isLineVisible: true }}
          mobile={{ margin: 120, isLineVisible: true }}
        />
        <Sponsors />
        <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 80 }} />
      </Spacing>
    </Layout>
  );
};

export const query = graphql`
  query ApplicationPageQuery {
    images: allFile(
      filter: { relativeDirectory: { regex: "/domain/application/" } }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default Applications;

Applications.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};
