import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Spacing from '@components/ui/spacing';
import ActionLink from '@components/ui/action-link';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackgroundImage from 'gatsby-background-image';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
import styles from './fluid-course-card.module.scss';
config.autoAddCss = false;

const FluidCourseCard = (props) => {
  const breakpoints = useBreakpoint();

  if (breakpoints.mobile) {
    return (
      <div className={styles.mobileContainer}>
        <div className={styles.mobileContent}>
          <BackgroundImage style={{ height: '100%', width: '100%', minHeight: 130 }} fluid={props.fluidImage} />
          <div className={styles.mobileDescription}>
            <Link className={styles.courseName} to={props.path}>{props.courseName}</Link>
            <label className={styles.mobileScholarity}>{props.scholarity}</label>
            <label className={styles.mobileEndDate}>
              <span><FontAwesomeIcon color="#da0000" icon={faClock} size="1x" /></span>
              <span>{props.endDate}</span>
            </label>
          </div>
          <div className={styles.mobileAction}>
            <ActionLink
              externalPath={props.applicationPath}
              label="candidata-te a este curso"
              width="100%"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Spacing>
        <div className={styles.content}>
          <section className={styles.leftSide}>
            <BackgroundImage style={{ height: 131, width: 160, minWidth: 160 }} fluid={props.fluidImage} />
            <div className={styles.description}>
              <Link className={styles.courseName} to={props.path}>{props.courseName}</Link>
              <div>
                <label className={styles.scholarity}>{props.scholarity}</label>
                <label className={styles.endDate}>
                  <span><FontAwesomeIcon color="#da0000" icon={faClock} size="1x" /></span>
                  <span>{props.endDate}</span>
                </label>
              </div>
            </div>
          </section>
          <div className={styles.action}>
            <ActionLink
              externalPath={props.applicationPath}
              label="candidata-te a este curso"
              width="100%"
            />
          </div>
        </div>
      </Spacing>
    </div>
  );
};

FluidCourseCard.propTypes = {
  courseName: PropTypes.string,
  scholarity: PropTypes.string,
  endDate: PropTypes.string,
  path: PropTypes.string,
  fluidImage: PropTypes.object,
  applicationPath: PropTypes.string,
};

export default FluidCourseCard;
